﻿.gallery-overview {
    .card-columns {
        column-count: 1;

        @include media-breakpoint-up(md) {
            column-count: 2;
        }

        @include media-breakpoint-up(lg) {
            column-count: 3;
        }
    }
}

html, body {
    height: 100%;
}

@include media-breakpoint-up(sm) {
    .sidebar-wrapper {
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        height: 100%;

        .sticky-element {
            position: -webkit-sticky;
            position: sticky;
        }
    }
}
