﻿.swiper-container {
    .swiper-slide {
        margin: 0;

        .overlay-box {
            bottom: 0;
            right: -15px;

            .text-wrapper {
                font-size: .8em;
                text-align: left;

                > div {
                    background: rgba(#6B8E4E, 0.85);
                }
            }
        }
    }

    .swiper-button {
        cursor: pointer;
        background: rgba(#fff, 0.9);
        z-index: 1;
        font-size: 2em;
        top: 0;
        height: 100%;

        &.swiper-button-next {
            right: 0;
        }
    }
}

@include media-breakpoint-up(sm) {
    .swiper-container {
        .swiper-slide {
            .overlay-box {
                .text-wrapper {
                    text-align: right;
                }
            }
        }

        .swiper-button {
            cursor: pointer;
            background: rgba(#fff, 0.9);
            z-index: 1;
            font-size: 2em;
            top: 0;
            height: 100%;

            &.swiper-button-next {
                right: 0;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .swiper-container {
        .swiper-slide {
            margin: 0 15px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .swiper-container {
        .swiper-button {   
            font-size: 4em;
        }
    }
}
