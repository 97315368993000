﻿.footer {
    background: #3C5148;
    border-bottom: 50px solid #1B2727;
    font-size: 1.2em;

    .lab {
        font-size: 2em;
        color: $orange;
    }

    .sticky-side {
        z-index: 10;
        right: 0;
        bottom: 0;

        a {
            margin: 0 1px 0 0;
            font-size: 1rem;
            background: #6b8e4e;
            @include link-colors(#fff);

            &:nth-child(odd) {
                background: #fff;
                @include link-colors(#6b8e4e);
            }

            &.social {
                background: $orange;
                padding: 2.5px;

                .lab {
                    padding-right: 3px;
                    @include link-colors(#fff);
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .footer {
        font-size: 1.3em;

        .sticky-side {
            top: 75vh;
            transform: rotate(90deg);
            transform-origin: top right;
            height: 50px;

            a {
                &.social {
                    .lab {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .footer {
        font-size: 1.5em;
    }
}
