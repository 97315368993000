﻿.intro-block {
    .overlay-box {
        bottom: 0;

        .headline {
            margin: -1.2em 0 .3em 0;
            font-size: 2.7em;
        }

        p {
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .intro-block {
        .overlay-box {
            position: absolute;

            .headline {
                margin: -1.2em 0 .3em -2.8em;
                font-size: 2.5em;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .intro-block {
        .overlay-box {
            .headline {
                font-size: 3.5em;
            }
        }
    }
}
