﻿nav {
    .nav-item {
        &.active {
            .nav-link-text {
                border-bottom: 3px solid #6B8E4E;
            }
        }

        .dropdown-menu {
            .active {
                .dropdown-link-text {
                    border-bottom: 1px solid #6B8E4E;
                }
            }
        }
    }
}

.yamm {
    .dropdown {
        &.yamm-fw {
            .dropdown-menu {
                transition: all 0.4s ease;
                font-size: 1em;

                &.show {
                    transition: all 0.4s ease;
                }
            }
        }
    }
}

.navbar-toggler {
    .line {
        fill: none;
        stroke: black;
        stroke-width: 6;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }

    .line2 {
        stroke-dasharray: 60 60;
        stroke-width: 6;
    }

    .line3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }

    &.opened {
        .line1 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
        }

        .line2 {
            stroke-dasharray: 1 60;
            stroke-dashoffset: -30;
            stroke-width: 6;
        }

        .line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6;
        }
    }
}

@include media-breakpoint-down(lg) {
    header {
        &.mobile-menu-open {
            background: $dropdown-bg;
        }
    }

    nav {
        &.yamm {
            .navbar-collapse {
                overflow-y: auto;
                position: fixed;
                top: 79px;
                right: -50vw;
                bottom: 0;
                width: 50vw;
                background: rgba($dropdown-bg, 0.9);
                transition: right 0.4s ease;

                &.show {
                    right: 0;
                }

                .nav-headline {
                    font-size: 1.3em;
                }
            }

            .dropdown-menu {
                background-color: unset;
            }

            .yamm-content {
                padding: 0;

                .col-wrapper {
                    padding: 0;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    nav {
        &.yamm {
            .navbar-collapse {
                right: -70vw;
                width: 70vw;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    nav {
        &.yamm {
            .navbar-collapse {
                top: 70px;
                width: 100vw;
                right: -100vw;
            }
        }
    }
}
