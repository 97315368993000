﻿.campaign-bar {

    .campaign-content-wrapper {
        font-family: $headings-font-family;

        h1 {
            font-size: 2.6em;
            margin: -1em 0 .1em;
        }

        p {
            a {
                color: inherit;
                text-decoration: underline;
            }
        }

        a {
            text-decoration: none;
        }
    }
}

@include media-breakpoint-up(sm) {
    .campaign-bar {
        .campaign-content-wrapper {
            h1 {
                font-size: 3em;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .campaign-bar {
        .overlay-box {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .campaign-content-wrapper {
            margin: -1em -1.5em .1em;

            h1 {
                font-size: 4em;
            }
        }
    }
}
