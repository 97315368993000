﻿#hero {
    background: #D5DDDF;

    .video-wrapper {
        overflow: hidden;

        video {
            object-fit: cover;
            width: 100%;
            min-height: 100%;
        }
    }

    &.fullwidth {
        .overlay-box {
            top: initial;
            bottom: 0;
            transform: initial;
        }
    }

    .hero-content-wrapper {
        font-family: $headings-font-family;

        h1 {
            font-size: 3.5rem;
            margin: -1em 0 0;
        }

        p {
            font-size: 1.2em;
        }

        a {
            text-decoration: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    #hero {
        .overlay-box {
            .hero-content-wrapper {
                h1 {
                    font-size: 4rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    #hero {
        .video-wrapper {
            max-height: 80vh;

            video {
                width: initial;
                min-width: 100%;
                max-height: 100%;
            }
        }

        &.fullwidth {
            max-height: 80vh;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .video-wrapper {
                video {
                    width: 100%;
                }
            }
        }

        .overlay-box {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            .hero-content-wrapper {
                h1 {
                    font-size: 4.9rem;
                    margin: -1em -1.5em 0em;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    #hero {
        .overlay-box {
            .hero-content-wrapper {
                h1 {
                    margin: -.8em -1.5em 0em;
                }
            }
        }
    }
}
