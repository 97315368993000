﻿// Custom mixins

/* Style placeholder on input fields
   Example:
   input {
        @include placeholder {
            font-family: $base-font-family;
            color: red;
        }
    }
*/
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder {@content}
    &::-moz-placeholder {@content}
    &:-ms-input-placeholder {@content}
}

@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
    color: $normal;

    @if $visited {
        &:visited {
            color: $visited;
        }
    }

    @if $focus {
        &:focus {
            color: $focus;
        }
    }

    @if $hover {
        &:hover {
            color: $hover;
        }
    }

    @if $active {
        &:active {
            color: $active;
        }
    }
}
