﻿.text-background-block {
    .headline {
        margin: -.9em 0 .3em 0;
        font-size: 2.7em;
    }

    .overlay-box {
        p {
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .text-background-block {
        .headline {
            font-size: 3.5em;
            margin: -1.4em 0 .3em 0;
        }

        .overlay-box {
            &.large-text {
                p {
                    font-size: 1.5em;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .text-background-block {
        .overlay-box {
            bottom: 0;
            position: absolute;

            &.large-text {
                .headline {
                    margin: -1.2em 0 .3em -2.4em;
                    font-size: 5.5em;
                }

                p {
                    font-size: 1.5em;
                }
            }

            .headline {
                margin: -1.5em 0 .3em -1.8em;
            }
        }
    }
}
