﻿#instagram-feed {
    background: #D5DDDF;

    .feed-headline {
        .h3 {
            background: #D5DDDF;
            color: #3C5148;
            z-index: 1
        }

        hr {
            top: 50%;
            left: 15px;
            right: 15px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            margin: 0;
            background: #3C5148;
        }
    }

    .instagram-video {
        .las {
            color: #fff;
            position: absolute;
            left: 0;
            font-size: 5em;
            margin: 0 auto;
            width: 100%;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
