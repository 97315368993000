﻿.employee-blocklist-wrapper {
    .headline {
        color: $orange;
        font-size: 2.7em;
    }

    .employee-blocklist {
        @include row-cols(4);

        img {
            cursor: pointer;
        }
    }
}

@include media-breakpoint-up(md) {
    .employee-blocklist-wrapper {
        .headline {
            font-size: 3.5em;
        }

        .employee-blocklist {
            @include row-cols(6);
        }
    }
}

@include media-breakpoint-up(lg) {
    .employee-blocklist-wrapper {
        .headline {
            font-size: 4em;
        }

        .employee-blocklist {
            @include row-cols(8);
        }
    }
}

@include media-breakpoint-up(xl) {
    .employee-blocklist-wrapper {
        .headline {
            font-size: 5em;
        }

        .employee-blocklist {
            @include row-cols(10);
        }
    }
}
