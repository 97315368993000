﻿.blockquote {
    position: relative;

    p {
        margin-bottom: 0.25em;
    }

    .las {
        position: absolute;
        top: -100px;
        left: -60px;
        font-size: 8em;
        opacity: 0.06;
    }
}
