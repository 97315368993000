﻿// Responsive iframes
iframe {
    max-width: 100%;
    border: none;
}

.noscroll {
    overflow: hidden;
}

// WinPhone fix
@-ms-viewport {
    width: auto;
}

img.lazyload {
    opacity: 0;
}

img.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}
