﻿header {
    background: rgba($body-bg, 0.8);
    transition: all 0.4s ease;

    &.active-dropdown {
        background: $dropdown-bg;
    }

    form {
        .form-control {
            border-color: #3C5148;
        }

        .btn-success {
            border-color: #3C5148;

            &:hover {
                border-color: #3C5148;
            }
        }
    }
}

@media (min-width: 1400px) and (max-width: 1620px) {
    header {
        form {
            .form-control {
                max-width: 95px;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    header {
        form {
            &.form-inline {
                .form-control {
                    width: calc(100% - 41px);
                }
            }
        }
    }
}
