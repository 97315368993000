﻿.collage-wrapper {
    .bg-element {
        background: #b2c5b2;
        top: 120px;
        bottom: 70px;
        right: 0;
        width: 85%;
    }

    .headline-wrapper {
        h2 {
            font-size: 3.5em;
        }

        p {
            margin-bottom: 0;
        }
    }

    .text-wrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(27, 39, 39, .8);
        width: 60%;
        height: 40%;
    }
}

@include media-breakpoint-up(sm) {
   
}

@include media-breakpoint-up(md) {
    
}

@include media-breakpoint-up(lg) {
    
}
