﻿$font-family-base: 'Montserrat', sans-serif;
$headings-font-family: 'Montserrat', sans-serif;

$primary: #3C5148;
//$secondary: #2ed3ae;

$orange: #FE7F2D;

$body-bg: #D5DDDF;

$link-color: $primary;

$success: #ABCC00;

//Navigation
$dropdown-bg: rgba(#B2C5B2, 0.9);
$dropdown-spacer: 0;
$dropdown-border-radius: 0;
$dropdown-border-width: 0;
$navbar-nav-link-padding-x: .7rem;

//Blockquote
$blockquote-font-size: 1rem * 1.75;


$btn-border-radius: 0;

$enable-responsive-font-sizes: true;

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1400px, xxl: 1600px );

$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1400px );

@import "../../node_modules/bootstrap/scss/variables";
